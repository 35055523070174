<template>
  <div>
    <v-img
      :src="require('../assets/forest-3287976_1280.jpg')"
      max-height="200"
      dark
    >
      <v-container>
        <div class="text-lg-h3 text-md-h3 text-sm-h4 text-xs-h4 mt-10">
          Andere Quizze
        </div>
        <div class="text-lg-h5 text-sm-h6">Du hast noch nicht genug von Rätseln über Baume?</div>
      </v-container>
    </v-img>
    <v-container class="mt-10">
      <v-row>
        <v-card max-width="350" class="mx-auto mt-5">
          <v-card-title>Österreichische Bundesforste</v-card-title>
          <v-card-text
            >Das Baumquiz der österreichen Bundesforste hat unterschiedliche
            Schwierigkeitsgrade von Anfänger bis Profi. Das Quiz gibt
            ausführliche Beschreibungen zu jeder Frage. Leider gibt es nur
            wenige Fragen, sodass man nach wenigen Durchgängen alles kennt.
          </v-card-text>
          <v-rating
            background-color="green lighten-3"
            color="green"
            :value="5"
            readonly
          ></v-rating>
          <v-card-actions>
            <v-btn
              plain
              href="https://www.bundesforste.at/natur-erleben/baumquiz.html"
              target="_blank"
              >Zeig's mir
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card max-width="350" class="mx-auto mt-5">
          <v-card-title>GEO</v-card-title>
          <v-card-text
            >Das Bilder-Quiz von GEO stellt 12 Fragen über Bäume. Leider ist die
            Reihenfolge der Bilder immer die Gleiche, sodass das Rätsel schnell
            langweilig wird.
          </v-card-text>
          <v-rating
            background-color="green lighten-3"
            color="green"
            :value="4"
            readonly
          ></v-rating>
          <v-card-actions>
            <v-btn
              plain
              href="https://www.geo.de/natur/naturquiz/16210-quiz-bilder-quiz-erkennen-sie-diese-baeume"
              target="_blank"
              >Zeig's mir
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card max-width="350" class="mx-auto mt-5">
          <v-card-title>Wissen.de</v-card-title>
          <v-card-text
            >Das Quiz von Wissen.de ist weniger ein Bilder-Quiz, sondern viel
            mehr ein Wissens-Quiz. Kennst du die Daten und Fakten rund über
            Bäume? Finde es heraus.
          </v-card-text>
          <v-rating
            background-color="green lighten-3"
            color="green"
            :value="4"
            readonly
          ></v-rating>
          <v-card-actions>
            <v-btn
              plain
              href="https://www.wissen.de/wissenstest/baum-quiz/take"
              target="_blank"
              >Zeig's mir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "OtherQuizzes",
  metaInfo: {
    title: 'Weitere Baumquizze im Internet - Baumquiz',
    meta: [
      { property: "description", content: 'Hier findest du eine Sammlung der besten Baumquizze im Internet samt einer kurzen Bewertung.' },
      { property: 'og:description', content: 'Hier findest du eine Sammlung der besten Baumquizze im Internet samt einer kurzen Bewertung.' },
    ],
  },
};
</script>
